.ui.dropdown.selection.checkable-dropdown {
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.5em;
}

.ui.dropdown.selection.checkable-dropdown:before {
    content:'';
    width:100%;
    height:100%;    
    position:absolute;
    right:0;
    top:0;
    background: linear-gradient(90deg, transparent 0%, transparent 75%, rgba(255,255,255,1) calc(100% - 28px));
}

.ui.dropdown.selection.checkable-dropdown > .dropdown.cancel.icon:before {
    content: '\f00d' !important;
}

.ui.popup.checkable-dropdown-popup {
    display: flex;
    flex-direction: column;
    max-height: 225px;
    padding: 0.25em 0;
    border: 1px solid #d4d4d5;
    overflow-y: auto;
    box-shadow: none !important;
}

.ui.popup.bottom.checkable-dropdown-popup {
    margin-top: -3px !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.ui.popup.top.checkable-dropdown-popup {
    margin-bottom: -3px !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.ui.popup.checkable-dropdown-popup .dropdown.item {
    justify-content: space-between;
}

.ui.popup.checkable-dropdown-popup .divider {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
    margin: 0.5em 0em;
}

.ui.popup.checkable-dropdown-popup .item {
    padding: 0.25em 1em;
}

.ui.popup.checkable-dropdown-popup .item.grouped {
    padding-left: 2.2em!important;
}


/* vvvv----- CSS Spécial pour GDS -----vvvv */

.ui.dropdown.selection.checkable-dropdown {
    height: 2.7rem;
    padding-left: 0.75em;
}

.ui.dropdown.selection.checkable-dropdown .divider.default.text {
    line-height: 1.5em;
}

.ui.dropdown.selection.checkable-dropdown,
.ui.dropdown.selection.checkable-dropdown:hover,
.ui.popup.checkable-dropdown-popup {
    border: 2px solid #5270a9;
}

.ui.dropdown.selection.checkable-dropdown .ui.label,
.ui.popup.checkable-dropdown-popup * {
    color: #5270a9 !important;
}

/* checkbox non cochée */
.ui.checkbox:not(.toggle):not(.radio) label:before {
    border: 2px solid #3479c7 !important;
    transition: all .1s ease-out;
}

.ui.dropdown.selection.checkable-dropdown .ui.label {
    background: transparent;
    box-shadow: none;
    padding-right: 0;
    padding-left: 0;
    line-height: 1.35em;
    font-size: 1.1em
}

.ui.dropdown.selection.checkable-dropdown .ui.label:after {
    content: ';';
    margin-left: 2px;
}

/* checkbox cochée */
.ui.checkbox:not(.toggle):not(.radio) input:checked ~ label:before {
    border: 2px solid #3479c7 !important;
    background-color: #0158ba !important;
    transition: all .1s ease-out;
}

/* contenu checkbox cochée */
.ui.checkbox input:checked ~ label:after {
    color: white !important;
    transition: all .1s ease-out;
}


/* checkbox indéterminée */
.ui.checkbox input:not([type="radio"]):indeterminate ~ label:before {
    background-color: #0158ba;
    transition: all .1s ease-out;
}

/* contenu checkbox indéterminée */
.ui.checkbox input:not([type="radio"]):indeterminate ~ label:after {
    color: white;
    transition: all .1s ease-out;
}

.ui.dropdown.selection.checkable-dropdown .icon.link {
    top: 1.1em;
}

/* ^^^^----- Fin CSS Spécial pour GDS -----^^^^ */


/* Affiche la pré-coche dans toutes les options du groupe au survol du groupe. */
.ui.popup.checkable-dropdown-popup .header.item:hover
    ~ .item .ui.checkbox:not(.checked) input~label:after {
    opacity: .35;
}

/* Enlève la pré-coche à partir du prochain divider. */
.ui.popup.checkable-dropdown-popup .header.item:hover
    ~ .divider
    ~ .item .ui.checkbox:not(.checked) input~label:after {
    opacity: .0;
}
