.ui.steps.styled {
    border: none;
    width: 100%;
    display: flex;
}

.ui.steps.styled .step > .icon {
    font-size: 1.2em;
    margin-right: 0;
    margin-top: 10px;
    /* les !important obligé car SUIR a des !important... */
    height: 1.8em !important;
    width: 1.8em !important;
}

.ui.steps.styled .step.active > .icon {
    background-color: #f99121 !important;
    color: transparent !important;
}

.ui.steps.styled .step.disabled > .icon {
    background-color: rgba(40, 40, 40, 0.3) !important;
    color: transparent !important;
}

.ui.steps.styled .step:not(.active):not(.disabled) > .icon {
    background-color: #90c647 !important;
    color: white !important;
}

.ui.steps.styled .step {
    display: flex;
    color: #90c647;
    flex: 1;
    flex-direction: column;
    border: none;
}

.ui.steps.styled .step.active {
    background: transparent;
    border: none;
}

.ui.steps.styled .step:after {
    transform: none;
    width: calc(100% - 40px);
    height: 4px;
    right: calc(-50% + 20px);
    top: 65%;
    background-color: #90c647;
    z-index: 1;
}