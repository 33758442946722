.resourceRow .ui.inverted.segment {
  border-radius: 0
}

.ui.grid.innerscroll>.row.scrollable {
  width: calc(100% + 19px) !important
}

.ui.bottom.right.popup:not(.application-launcher-popup) {
  padding: .833em 1em;
}


.ui-picker {
  &-input {
    ::placeholder {
      color: hsla(220,4%,86%,.87) !important;
    }
  }

  &-range.ui-picker.field.inputReadOnly {
    background-color: white;
    cursor: pointer;

    * {
      cursor: pointer;
    }
    
    .ui-picker-clear {
      background-color: white;    
    }
  }
}
